<template>
	<div id="demo" class="pdf"></div>
</template>
<script>
  import Pdfh5 from "pdfh5";
  export default {
    name: 'App',
	data() {
	  return {
	    pdfh5: null
	  };
	},
	mounted() {
		//实例化
	  this.pdfh5 = new Pdfh5("#demo", {
		  pdfurl: ""
	  });
	  //监听完成事件
	  this.pdfh5.on("complete", function (status, msg, time) {
	  })
	}
  }
</script>

<style lang="scss" scoped>
	.pdf {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .viewerContainer{
      height: 100%;
      width: 100%;
      *{
        max-width: 100%;
      }
    }
	}
</style>
